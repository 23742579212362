<div class="body_bg">
  <section class="top_header_section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="top_setting_main">
            <div class="kwot_logo">
              <img src="assets/images/Logo.png" />
            </div>
            <div class="upload_skit">
              <div>
                <ol>
                  <li
                    [ngClass]="{ active: activeTab === 'billing' }"
                    (click)="changeTab('billing')"
                  >
                    Billing
                  </li>
                  <li
                    [ngClass]="{ active: activeTab === 'payment' }"
                    (click)="changeTab('payment')"
                  >
                    Payment
                  </li>
                </ol>
              </div>
            </div>
            <div class="category_cross">
              <img src="assets/images/Cross1.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="steps_section_main">
    <div class="row">
      <div class="col-md-3 pr_0">
        <div class="type_artist">
          <div class="artsit_heading">
            <h1>Buy your plan</h1>
            <p>Donec vel ultricies orci ut vel orci ut leo ultricies donec.</p>
          </div>
        </div>
      </div>
      <div class="col-md-9 pl_0">
        <div class="row">
          <form [formGroup]="paymentForm">
            <div class="col-md-12">
              <div class="inner-token">
                <ng-container *ngIf="activeTab === 'billing'">
                  <div class="inner-token-head mt-md-0 mt-3">
                    <h2>Review your details</h2>
                    <h5 *ngIf="billingAddress">Billing details</h5>
                  </div>
                  <div class="surname-card" *ngIf="billingAddress">
                    <div>
                      <h5>
                        {{
                          billingAddress?.first_name +
                            ' ' +
                            billingAddress?.last_name
                        }}
                      </h5>
                      <p>
                        {{
                            (billingAddress?.address_line_1 || '') +
                            ', ' +
                            (billingAddress?.address_line_2 || '') +
                            ', ' +
                            (billingAddress?.city || '') +
                            ', ' +
                            (billingAddress?.province_id?.name || '') +
                            ', ' +
                            (billingAddress?.country_id?.name || '') +
                            ', ' +
                            (billingAddress?.postal_code || '')
                        }}
                      </p>
                    </div>
                    <!--                      <div><img src="assets/images/token-edit.png" /></div>-->
                    <div></div>
                  </div>
                  <br />

                  <div class="token-cards-payment">
                    <h5>Tokens</h5>
                    <div class="surname-card d-block col-md-8">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <h5 class="input-label">Add Tokens</h5>
                        <ng-container
                          *ngIf="
                            (form['tokens'].touched || form['tokens'].dirty) &&
                            form['tokens'].errors
                          "
                        >
                          <p
                            *ngIf="form['tokens'].errors['required']"
                            class="text-danger mb-0 me-2 fs-10"
                          >
                            Tokens is required
                          </p>
                          <p
                            *ngIf="form['tokens'].errors['min']"
                            class="text-danger mb-0 me-2 fs-10"
                          >
                            Minimum tokens should be 20
                          </p>
                          <p *ngIf="form['tokens'].errors['noDecimal']" class="text-danger mb-0 me-2 fs-10">
                            Tokens cannot be a decimal value
                          </p>
                        </ng-container>
                      </div>
                      <input
                        class="form-control"
                        type="number"
                        placeholder="Enter tokens"
                        formControlName="tokens"
                        (input)="addTokens($event)"
                      />

                    </div>
                    <div class="card-three cards active">
                      <p>
                        <frontend-currency [tag]="'span'" [price]="tokenDetails?.tokenPrice || 0" [type]="'token'"></frontend-currency>
                        ({{ tokenDetails?.tokens || 0 }} TOKENS)
                      </p>
                    </div>
                  </div>
                  <div class="explicit_content skits_title price_Checkbox">
                    <div class="explicit_content_radio plan_checkbox">
                      <p class="position-relative">
                        <input
                          type="checkbox"
                          name="radio-group"
                          class="radio"
                          (click)="changeTerms($event)"
                          [checked]="isAcceptTerms"
                        />
                        <label>
                          I have read and accept the Purchase Conditions and
                          understand the information on the user of my personal
                          data explained in the Privacy Policy</label
                        >
                      </p>
                    </div>
                  </div>
                  <div>
<!--                    <button-->
<!--                      class="support-btn"-->
<!--                      (click)="changeTab('payment')"-->
<!--                      [disabled]="paymentForm.invalid"-->
<!--                    >-->
<!--                      Confirm Payment-->
<!--                    </button>-->

                    <div class="modal-footer">
                      <button
                        class="btn-confirm"
                        (click)="changeTab('payment')"
                        [ngClass]="{ active: !paymentForm.invalid }"
                        [disabled]="paymentForm.invalid"
                      >
                        Confirm Payment
                      </button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="activeTab === 'payment'">
                  <div class="token-rupees-value">
                    <div
                      class="d-flex justify-content-between inner-token-values"
                    >
                      <p>{{ tokenDetails?.tokens }} tokens</p>
                      <frontend-currency [tag]="'span'" [price]="tokenDetails?.tokenPrice || 0" [type]="'token'"></frontend-currency>
                    </div>
                    <div class="d-flex justify-content-between total-values">
                      <div class="inner-token-values">
                        <h6>TOTAL</h6>
                        <p>Taxes included when applicable</p>
                      </div>
                      <frontend-currency [tag]="'span'" [price]="tokenDetails?.tokenPrice || 0" [type]="'token'"></frontend-currency>
                    </div>
                  </div>
                  <div class="token-cards-payment">
                    <h5>Payment</h5>
                    <frontend-payments
                      (disablePaymentEvent)="disablePaymentEvent($event)"
                      (paymentOptionEvent)="paymentOptionEvent($event)"
                      [tokenDetails]="tokenDetails"
                      [isConfirmPayment]="isConfirmPayment"
                    >
                    </frontend-payments>
                  </div>
                  <button
                    class="btn-confirm"
                    *ngIf="paymentOption === 'stripe'"
                    [ngClass]="{
                      active: !disablePayment,
                      'btn-disabled': disablePayment
                    }"
                    [disabled]="disablePayment"
                    (click)="confirmPayment()"
                  >
                    Confirm Payment
                  </button>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
