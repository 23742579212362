import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {APP_CONFIG} from '@frontend/app-config';
import {StripeComponent} from '../stripe/stripe.component';
import {Subject, takeUntil} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {
  AuthState,
  getLoggedInUser,
  PurchaseToken,
} from '@frontend/auth-store';
import {CurrencyData} from '@frontend/data-models';
import {AdminState} from '@frontend/admin-store';
import {PaymentGatwayService} from '../../services/payment-gatway.service';

@Component({
  selector: 'frontend-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy, OnChanges {
  paymentOption: 'stripe' | 'flutterwave' | 'alatpay' | '' = '';
  calculatedAmount: any;
  currentuser: any;
  disablePayment = true;
  @ViewChild('stripComponent', {static: false})
  stripComponent: StripeComponent;
  currencyData: CurrencyData;
  // currencyData: CurrencyData = {location: {countryName: 'Nigeria', countryCode: 'NG', currencyCode: 'NGN', currencySymbol: '₦'}};
  // currencyData: CurrencyData = {location: {countryName: 'India', countryCode: 'IN', currencyCode: 'USD', currencySymbol: '$'}};
  unsubscriber = new Subject();

  @Input() isConfirmPayment: any;
  @Input() tokenDetails: any;
  @Output() disablePaymentEvent = new EventEmitter();
  @Output() paymentOptionEvent = new EventEmitter();

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private authStore: Store<AuthState>,
    private adminStore: Store<AdminState>,
    private paymentService: PaymentGatwayService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.subscribeToStore();
    this.calcFinalAmount();
    this.paymentService.getConversationRates().subscribe((data: any) => {
      this.currencyData = data.data;
      // this.currencyData.location = {countryName: 'Nigeria', countryCode: 'NG', currencyCode: 'NGN', currencySymbol: '₦'};

      if (this.currencyData.location.countryName?.toLowerCase() === 'nigeria' && this.currencyData.location.countryCode?.toLowerCase() === 'ng') {
        this.paymentOption = 'flutterwave';
      } else {
        this.paymentOption = 'stripe';
      }
      // Update ViewChild stripeComponent to find the reference
      this.cdr.detectChanges();
      if (this.paymentOption === 'stripe') {
        this.createStripeIntent();
      }
    });
  }

  ngOnChanges(): void {
    if (this.isConfirmPayment) {
      this.completePayment();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

  calcFinalAmount() {
    this.calculatedAmount = Number((Number(this.tokenDetails?.payment?.value) || Number(this.tokenDetails?.tokenPrice) || Number(this.tokenDetails?.value) || 0).toFixed(2));
  }

  subscribeToStore() {
    this.adminStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => {
        if (user) {
          this.currentuser = user;
        }
      });
  }

  confirmPayment(result: any) {
    console.log('===================')
    let body: any = {
      paymentData: {
        paymentOption: this.paymentOption,
        paymentDetails: result,
      },
    };
    if (this.paymentOption === 'stripe') {
      body.paymentData.status = result.paymentIntent.status;
    } else if (this.paymentOption === 'flutterwave') {
      body.paymentData.status = result.status;
    } else if (this.paymentOption === 'alatpay') {
      body.paymentData.status = result.status;
    }

    console.log('body', body)
    this.authStore.dispatch(
      PurchaseToken({
        body: {
          tokens: Number(this.tokenDetails?.tokens) || (this.currentuser.role === 'user' ? Number(this.tokenDetails?.no_of_tokens) : this.tokenDetails?._id),
          accept_terms: true,
          total_amount:
            this.tokenDetails?.totalTokensPrice || this.tokenDetails?.value,
          total_amount_with_tax:
            this.tokenDetails?.totalTokensPrice || this.tokenDetails?.value,
          billing_details: this?.tokenDetails?.billing_details?._id,
          role: this.currentuser.role,
          purchase_id: this.paymentOption === 'flutterwave' ? body.paymentData.paymentDetails.flw_ref : body.paymentData.paymentDetails.paymentIntent.id,
          payment_intent: this.paymentOption === 'flutterwave' ? body.paymentData.paymentDetails : body.paymentData.paymentDetails.paymentIntent,
          paymentOption: this.paymentOption
        },
      })
    );
    console.log('===========================>>>>>>>>>>')
    this.disablePaymentEvent.emit(!this.disablePayment);
  }

  validatePayment(event: any) {
    this.disablePayment = !event;
    this.disablePaymentEvent.emit(this.disablePayment);
    this.paymentOptionEvent.emit(this.paymentOption);
  }

  createStripeIntent() {
    if (this.stripComponent) {
      this.stripComponent.createStripePaymentIntent(Number(this.tokenDetails?.payment?.value) || this.tokenDetails?.totalTokensPrice || this.tokenDetails?.value || Number(this.tokenDetails?.tokens) || 0, this.tokenDetails.currency || 'USD');
    } else {
      setTimeout(() => {
        this.createStripeIntent();
      }, 500);
    }
  }

  completePayment() {
    if (this.paymentOption === 'stripe') {
      this.stripComponent.completePayment();
    }
  }
}
