// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://xar804cw9l.execute-api.us-east-1.amazonaws.com/dev/v1/',
  // apiUrl: "http://localhost:5000/v1/",
  b2cUrl: "https://t8jgpjuvp1.execute-api.us-east-1.amazonaws.com/prod/v1/",
  applicationType: 'user',
  artistUrl: 'https://artist-dev.kwot.com/sign-in',
  stripe_publishable_key: 'pk_test_51N5ng7Fqr1KAmqKdJQ1Sq06uOR1PeQnCEuLR8Nvpx1aR5jVQPalGSsrPSXECsa4s6OjpY8QqIgfbbVcqYRrcOshU00PEXEAdXg',
  flutterwave_public_key: 'FLWPUBK_TEST-80d407d37e833d27417a2a0e57117ab5-X',
  alatPayUrl: 'https://alatpay-client.azurewebsites.net/js/alatpay.js',
  alatPayBusinessId: 'cca1e068-2220-49a9-4181-08dafc0c53a0',
  alatPayApiKey: '4401dcd8e06c4c768d2e30619767e86b',
  agoraAppId: 'b8d6d3eb2f1444f2b0bec339e82ff50c',
  websocket: 'wss://udzto19ooj.execute-api.us-east-1.amazonaws.com/dev',
  type: 'user'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
