import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { VideoModalComponent } from '../../_components/video-modal/video-modal.component';
import { select, Store } from '@ngrx/store';
import {
  AuthState,
  getAuthError,
  getAuthSuccess,
  getLoggedInUser,
  LoginWithToken,
  ResetAuthState,
} from '@frontend/auth-store';
import { CurrencyData } from '@frontend/data-models';
import {
  getError,
  getTokenDetails,
  GetTokenDetails,
  PublicState,
  ResetPublicState,
} from '@frontend/public-store';
import { PaymentGatwayService } from '../../../../../../libs/payment-gatways/src/lib/services/payment-gatway.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
// import { ToastrService } from 'ngx-toastr';
import { LocalstorageService } from 'libs/app-config/services/localstorage.service';

@Component({
  selector: 'frontend-choose-token',
  templateUrl: './choose-token.component.html',
  styleUrls: ['./choose-token.component.scss'],
})
export class ChooseTokenComponent implements OnInit {
  paymentForm: UntypedFormGroup;
  disablePayment: boolean = true;
  isConfirmPayment: boolean = false;
  currencyData: CurrencyData;
  @Output() confirmPaymentEvent = new EventEmitter();
  paymentOption: any;
  tokenDetails: any;
  activeTab: string = 'billing';
  billingAddress: any = '';
  unsubscriber = new Subject();
  isPayment: boolean = false;
  isAcceptTerms: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private authStore: Store<AuthState>,
    private publicStore: Store<PublicState>,
    private paymentService: PaymentGatwayService,
    private activeRoute: ActivatedRoute,
    private localStorageService: LocalstorageService,
    // private toastr: ToastrService,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((query: any) => {
      if (query && query.token) {
        this.authStore.dispatch(LoginWithToken({ token: query.token }));
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
          this.authStore.dispatch(LoginWithToken({ token }));
        }
      }
    });
    this.authStore.dispatch(
      ResetAuthState({ params: { error: '', success: '' } })
    );
    this.publicStore.dispatch(
      ResetPublicState({ params: { error: '', success: '' } })
    );
    this.subscribeToStore();
    this.paymentForm = this.formBuilder.group({
      tokens: ['20', [Validators.required, Validators.min(20), this.noDecimalValidator]],
      accept_terms: [null],
    });
  }

  noDecimalValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && !Number.isInteger(Number(value))) {
      return { noDecimal: true }; // Return an error object if the value is not an integer
    }
    return null; // Valid if the value is an integer
  }

  subscribeToStore() {
    this.authStore.pipe(select(getAuthSuccess)).subscribe((success: any) => {
      if (success && success !== 'Payment Inntent Saved') {
        // this.toastr.success(success);
        if (this.isPayment) {
          this.isPayment = false;
          this.openModal();
          // this.toastr.success(success);
        }
      }
    });

    this.authStore.pipe(select(getAuthError)).subscribe((error: any) => {
      if (error) {
        console.log('error from getAuthError', error);
        this.localStorageService.clearAllLocalStorage();
        this.router.navigate(['/sign-in']);
      }
    });

    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user: any) => {
        if (user) {
          this.localStorageService.updateUserKey('store', user);
          if (this.currencyData) {
            this.publicStore.dispatch(GetTokenDetails());
          }
        }
      });

    this.publicStore
      .pipe(select(getTokenDetails))
      .subscribe((tokenDetails: any) => {
        if (tokenDetails) {
          this.tokenDetails = tokenDetails;

          this.billingAddress = this.tokenDetails.billing_details;
          console.log('this.paymentForm', this.paymentForm.value);
          const tokens = this.paymentForm.value.tokens;
          const amt = (tokens * this.tokenDetails?.tokens_price?.total_price) / this.tokenDetails?.tokens_price?.no_of_token;
          this.tokenDetails = {
            ...this.tokenDetails,
            tokens: tokens,
            tokenPrice: Number(amt.toFixed(2) || 0),
            totalTokensPrice: Number(amt.toFixed(2)) || 0,
          };
        }
      });

    this.publicStore.pipe(select(getError)).subscribe((error: any) => {
      if (error) {
        console.log('============= getError', error);
        this.localStorageService.clearAllLocalStorage();
        this.router.navigate(['/sign-in']);
      }
    });
  }

  ngOnInit(): void {
    this.paymentService.getConversationRates().subscribe((data: any) => {
      if (data) {
        this.publicStore.dispatch(GetTokenDetails());
        this.currencyData = data.data;
        // this.currencyData.location = {countryName: 'Nigeria', countryCode: 'NG', currencyCode: 'NGN', currencySymbol: '₦'};
      }
    });
  }

  get form() {
    return this.paymentForm.controls;
  }

  openModal() {
    const modelRef: BsModalRef = this.modalService.show(VideoModalComponent, {
      animated: true,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered video-tokens-model modal-small',
      initialState: {
        tokenDetail: this.tokenDetails,
      },
    });
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.localStorageService.clearAllLocalStorage();
        this.router.navigate(['']);
      }
    });
  }

  confirmPayment() {
    this.isConfirmPayment = true;
    this.confirmPaymentEvent.emit(this.isConfirmPayment);
  }

  disablePaymentEvent(result: any) {
    console.log('result', result)
    this.disablePayment = result;
    this.isPayment = true;
  }

  paymentOptionEvent(result: any) {
    this.paymentOption = result;
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }

  addTokens(event: any) {
    this.paymentForm.patchValue({ tokens: event.target.value });
    const tokens = this.paymentForm.value.tokens;
    const amt = (tokens * this.tokenDetails?.tokens_price?.total_price) / this.tokenDetails?.tokens_price?.no_of_token || 0;
    this.tokenDetails = {
      ...this.tokenDetails,
      tokens: tokens,
      tokenPrice: Number(amt.toFixed(2) || 0),
      totalTokensPrice: Number(amt.toFixed(2)) || 0,
    };
  }

  changeTerms(event: any) {
    this.isAcceptTerms = event.target.checked;
    if (event.target.value) {
      this.paymentForm.patchValue({ accept_terms: event.target.checked });
    } else {
      this.paymentForm.patchValue({ accept_terms: null });
    }
  }
}
