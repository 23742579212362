<div class="payment-container margin">
  <div class="container">
    <div class="row justify-content-center">
      <div class="mb-4">
        <div
          *ngIf="paymentOption === 'flutterwave' || paymentOption === 'alatpay'"
        >
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <span
                class="nav-link cursor-pointer"
                (click)="paymentOption = 'flutterwave'"
                [ngClass]="{ active: paymentOption === 'flutterwave' }"
                >Flutterwave</span
              >
            </li>
<!--            <li class="nav-item">-->
<!--              <span-->
<!--                class="nav-link cursor-pointer"-->
<!--                (click)="paymentOption = 'alatpay'"-->
<!--                [ngClass]="{ active: paymentOption === 'alatpay' }"-->
<!--                >Alatpay</span-->
<!--              >-->
<!--            </li>-->
          </ul>
        </div>
        <div class="payment">
          <div class="fs-16 fs-xs-12 color255A61 font-weight-medium">
            <span class="cursor-pointer payment-header" *ngIf="paymentOption">
              <ng-container *ngIf="paymentOption === 'stripe'">
                {{ 'stripe_payment' | translate }}
              </ng-container>
              <ng-container *ngIf="paymentOption === 'flutterwave'">
                {{ 'Flutterwave' }}
              </ng-container>
<!--              <ng-container *ngIf="paymentOption === 'alatpay'">-->
<!--                {{ 'Alatpay' }}-->
<!--              </ng-container>-->
            </span>
          </div>
          <div class="payment-options">
            <ng-container *ngIf="paymentOption === 'flutterwave'">
              <frontend-flutterwave-payment
                #flutterwavePaymentComponent
                (paymentConfirmed)="confirmPayment($event)"
                [tokenDetails]="tokenDetails"
                [calculatedAmount]="calculatedAmount"
                [currencyData]="currencyData"
                [currentuser]="currentuser"
              >
              </frontend-flutterwave-payment>
            </ng-container>
<!--            <ng-container *ngIf="paymentOption === 'alatpay'">-->
<!--              <frontend-alat-pay-->
<!--                (paymentConfirmed)="confirmPayment($event)"-->
<!--                [tokenDetails]="tokenDetails"-->
<!--                [calculatedAmount]="calculatedAmount"-->
<!--                [currencyData]="currencyData"-->
<!--                [currentuser]="currentuser"-->
<!--              >-->
<!--              </frontend-alat-pay>-->
<!--            </ng-container>-->
            <ng-container *ngIf="paymentOption === 'stripe'">
              <frontend-stripe
                #stripComponent
                [currentuser]="currentuser"
                [calculatedAmount]="calculatedAmount"
                [currencyData]="currencyData"
                (disabledPayment)="validatePayment($event)"
                (paymentConfirmed)="confirmPayment($event)"
              ></frontend-stripe>
            </ng-container>
            <!--            <button-->
            <!--              type="button"-->
            <!--              *ngIf="paymentOption === 'stripe'"-->
            <!--              id="pay-button"-->
            <!--              [disabled]="disablePayment"-->
            <!--              (click)="completePayment()"-->
            <!--              [ngClass]="{-->
            <!--                    'btn-action': !disablePayment,-->
            <!--                    'btn-disabled': disablePayment-->
            <!--                  }">-->
            <!--              Pay-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
