import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {StripePaymentElementComponent, StripeService} from "ngx-stripe";
import {Appearance, PaymentRequestOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {ToastrService} from "ngx-toastr";
import {APP_CONFIG, LoaderService, LocalstorageService} from "@frontend/app-config";
import {PaymentGatwayService} from "../../services/payment-gatway.service";
import {AuthState, PaymentIntent} from "@frontend/auth-store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'frontend-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;
  appearance: Appearance = {
    theme: "stripe",
    variables: {
      fontFamily: "DM Sans, sans-serif",
      fontSizeBase: '16px',
      borderRadius: '16px',
      colorPrimary: "#255A61",
      colorDanger: "#FE7F7F"
    }
  }
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentRequestOptions: PaymentRequestOptions;
  paymentIntent: any
  @Input() currentuser: any;
  @Input() calculatedAmount: any;
  @Input() disabledPayement: any;
  @Output() disabledPayment = new EventEmitter();
  @Output() paymentConfirmed = new EventEmitter();
  @Output() intentCreated = new EventEmitter();
  @Input() currencyData: any;
  showOr = false;
  recursionCount = 0;

  constructor(
    private stripeService: StripeService,
    private commonService: PaymentGatwayService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private ls: LocalstorageService,
    private authStore: Store<AuthState>,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
  }

  ngOnInit(): void {
    this.stripeService.changeKey(this.appConfig.stripe_publishable_key);
  }

  createPaymentIntent(body: any) {
    if (this.ls.updateStripePayment('get')) {
      body.intentId = this.ls.updateStripePayment('get').id
    }
    return this.commonService.createPaymentIntent(body);
  }

  createStripePaymentIntent(amount: any, currency = '') {
    this.paymentRequestOptions = {
      country: this.currencyData.location.countryCode,
      currency: currency.toLowerCase() || this.currencyData.location.currencyCode.toLowerCase(),
      total: {
        label: 'Purchase Token',
        amount: Number(Number(Number(amount.toFixed(2)) * 100).toFixed(2)),
      },
      requestPayerName: true,
      requestPayerEmail: true,
      wallets: ['applePay', 'googlePay']
    }
    this.loaderService.show();
    this.createPaymentIntent({amount, currency: currency || this.currencyData.location.currencyCode, useId: this.currentuser._id, userName: this.currentuser.first_name + ' ' + this.currentuser.last_name})
      .subscribe((paymentIntent: any) => {
        this.loaderService.hide();
        this.paymentIntent = paymentIntent.data;
        this.authStore.dispatch(
          PaymentIntent({
            body: {
              purchase_id: this.paymentIntent.id,
              currency: currency
            },
          })
        );
        this.ls.updateStripePayment('store', this.paymentIntent);
        this.elementsOptions.clientSecret = paymentIntent.data.client_secret;
        this.intentCreated.emit(true);
      }, (error: any) => {
        this.recursionCount = this.recursionCount + 1;
        this.ls.updateStripePayment('remove');
        if (this.recursionCount < 5) {
          this.createStripePaymentIntent(amount);
        } else {
          this.loaderService.hide();
          this.toastr.error(error?.error?.message || error?.message || 'Something went wrong');
        }
      });
  }

  invalidEvents(event: any) {
    if (event.complete) {
      this.disabledPayment.next(true)
    } else {
      this.disabledPayment.next(false)
    }
  }

  completePayment() {
    this.loaderService.show();
    let name = 'Guest';
    if (this.currentuser) {
      if (this.currentuser.name) {
        name = this.currentuser.name;
      } else {
        name = (this.currentuser.firstName || '') + (this.currentuser.lastName || '')
      }
    }
    this.stripeService.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: name
          }
        }
      },
      redirect: 'if_required'
    }).subscribe((result: any) => {
      this.loaderService.hide();
      this.ls.updateStripePayment('remove');
      if (result.error) {
        this.toastr.error(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          this.paymentConfirmed.next(result);
        }
      }
    });
  }

  onPaymentMethod(event: any) {
    this.stripeService.confirmCardPayment(this.elementsOptions.clientSecret, {
      payment_method: event.paymentMethod.id,
    }, {handleActions: false}).subscribe(result => {
      this.ls.updateStripePayment('remove');
      if (result.error) {
        this.toastr.error(result.error.message);
        event.complete('fail');
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          const finalResult = {...result, paymentIntent: {...result.paymentIntent, walletName: event.walletName}}
          console.log('finalResult', finalResult)
          this.paymentConfirmed.next(finalResult);
          event.complete('success');
        }
        // if (result.paymentIntent.status === "requires_action") {
        //   this.stripeService.confirmCardPayment(this.elementsOptions.clientSecret).subscribe(result => {
        //     if (result.error) {
        //       this.toastr.error(result.error.message);
        //     } else {
        //       this.paymentConfirmed.next(result);
        //       event.complete('success');
        //     }
        //   });
        // }
      }
    })
  }

  onNotAvailable() {
    console.log('NOT AVAILABLE');
  }

  onSource(event: any) {
    console.log(event);
  }

  cancelEvent() {
    console.log('USER CANCELED');
  }

  onReady() {
    this.showOr = true;
  }

  validatePayment(event: any) {
    if (this.disabledPayement) {
      this.toastr.warning('Please accept the terms and conditions')
      event.preventDefault();
    }
  }

  // createPaymentLink(body: any) {
  //   return this.commonService.createPaymentLink(body);
  // }
}
